<template>
    <div class="class-manage-resources">
        <el-table :data="classResourcesTable" border style="width: 100%; flex: 1" height="1%" size="medium" :row-key="getRowKey" :expand-row-keys="expands" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="rc_name" label="名称"></el-table-column>
            <el-table-column label="操作" align="right">
                <template slot-scope="scope">
                    <el-button size="small" type="success" v-if="scope.row.level === 1" @click="addClass(scope.row)">添加专业中类</el-button>
                    <el-button size="small" type="primary" v-if="scope.row.level === 2" @click="addClass(scope.row)">添加专业</el-button>
                    <el-button size="small" @click="editClass(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteClassRow(scope.row.rc_id, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-right" :current-page.sync="currentPageResources" layout="prev, pager, next, jumper" :total="pageTotal" @current-change="handleCurrentChange">
        </el-pagination>

        <el-dialog :title="classTitleType" :visible.sync="dialogAddClass" center width="500px" @close="resetForm()" @opened="openAddClass" :close-on-click-modal="false">
            <el-form :model="addClassForm" ref="addClassForm" :rules="resourcesRules" :label-position="labelPosition">
                <el-form-item label="分类名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addClassForm.name" ref="nameInput" autocomplete="off" placeholder="1-20字符" style="width: 80%;" ></el-input>
                </el-form-item>
                <el-form-item label="上传封面" :label-width="formLabelWidth" prop="image"
                              v-if="classTitleType === '添加专业' || classTitleType === '编辑专业'">
                    <el-button type="primary" v-model="addClassForm.image" class="upload-btn" size="medium">
                        <span>{{addClassForm.image ? '' : '上传封面'}}</span>
                        <input type="file" accept="image/jpg, image/jpeg, image/png" title="" @change="changeCoverFile($event)">
                    </el-button>
                    <div class="up-img-show" v-if="addClassForm.image">
                        <img :src="addClassForm.image" alt />
                        <div class="cover-bg">
                            <span class="re-upload">重新上传</span>
                            <input type="file" accept="image/jpg, image/jpeg, image/png" title="" @change="changeCoverFile($event)">
                        </div>
                    </div>
                    <span class="cover-text">尺寸：258x190px</span>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddClass = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addClassForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        created(){
            this.list();
        },
        data() {
            return {
                page:1,
                pageTotal:0,
                classResourcesTable: [],
                expands: [],
                currentPageResources: 1,
                currentCategory: null,
                dialogAddClass: false,
                labelPosition: 'left',
                addClassForm: {
                    name: '',
                    id:'',
                    level:'',
                    image: '',
                    imageFile:'',
                    imageFilePath:'',
                },
                formLabelWidth: '80px',
                classTitleType: '',
                distinguishBtn: '',
                resourcesRules: {
                    name: [
                        { required: true, message: '请输入分类名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    image: [
                        { required: true, message: '请上传封面', trigger: 'change' }
                    ],
                }
            }
        },
        methods: {
            getRowKey(row) {
                return row.rc_id;
            },
            getRowKeys(data) {
                let arr = [];
                if (data.length === 0) {
                    return arr;
                }
                arr.push(data[0].rc_id);
                if (data[0].children && data[0].children.length > 0) {
                    let result = this.getRowKeys(data[0].children);
                    arr = arr.concat(result);
                }
                return arr;
            },
            addClass(row = null) {
                if(row){
                    this.addClassForm.id = row.rc_id;
                    this.addClassForm.level = row.level;
                    if (row.level === 1) {
                        this.classTitleType = '添加专业中类'
                    }
                    if (row.level === 2) {
                        this.classTitleType = '添加专业'
                    }
                }else{
                    this.addClassForm.level = 0;
                    this.classTitleType = '添加专业大类'
                }
                this.dialogAddClass = true;
                this.currentCategory = row;
                this.distinguishBtn = 'add';
            },
            editClass(row = null) {
                if(row){
                    this.addClassForm.id = row.rc_id;
                    this.addClassForm.level = row.level;
                    if (row.level === 1) {
                        this.classTitleType = '编辑专业大类'
                    }
                    if (row.level === 2) {
                        this.classTitleType = '编辑专业中类'
                    }
                    if (row.level === 3) {
                        this.classTitleType = '编辑专业'
                    }
                    if (row.file_path) {
                        this.addClassForm.image = row.file_path.src;
                    }
                }else{
                    this.addClassForm.level = 0;
                }
                this.dialogAddClass = true;
                this.currentCategory = row;
                this.addClassForm.name = row.rc_name;
                this.distinguishBtn = 'edit';
            },
            //上传封面
            changeCoverFile(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                let maxSize = 1024 * 1024 * 2;
                this.addClassForm.imageFile = fileList;
                let url = '';
                let reader = new FileReader();
                reader.readAsDataURL(fileList);
                let that = this;
                reader.onload = function (e) {
                    // url = this.result.substring(this.result.indexOf(',') + 1);
                    // that.addClassForm.image = "data:image/png;base64," + url;

                    let img = new Image();
                    img.src = e.target.result;
                    img.onload = () => {
                        if (img.width === 258 && img.height === 190 && fileList.size <= maxSize) {
                            let formData = new FormData();
                            formData.append('file', that.addClassForm.imageFile);
                            that.$http.axiosPost(that.$api.admin_uploadsClassifyImg, formData, (res) => {
                                if (res.code === 200) {
                                    that.addClassForm.imageFilePath = res.data.url;
                                    that.addClassForm.image = res.data.src;
                                    that.$message.success(res.msg);
                                } else {
                                    that.$message.error(res.msg);
                                }
                            }, (err) => {
                                console.log(err);
                            })
                        } else {
                            that.$message({
                                type: "error",
                                message: "图片尺寸必须为 258*190，大小不能超过2M",
                            });
                        }
                    }
                }
                event.target.value = '';
            },
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            rc_type: 4,
                            rc_name: this.addClassForm.name
                        }
                        if (this.addClassForm.id) {
                            params.rc_id = this.addClassForm.id;
                        }
                        if (this.addClassForm.imageFilePath) {
                            params.up = true;
                            params.file_path = this.addClassForm.imageFilePath;
                        }
                        if (this.distinguishBtn === 'add') {
                            let rowCate = this.currentCategory;
                            this.$http.axiosGetBy(this.$api.resourceClassAdd, params, (res) => {
                                if(res.code === 200){
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: res.msg,
                                        onClose: ()=> {
                                            this.dialogAddClass = false;
                                            if (this.addClassForm.id) {
                                                this.currentCategory.children.unshift({
                                                    rc_id: res.rc_id,
                                                    rc_name: this.addClassForm.name,
                                                    rc_parent_id: this.addClassForm.id,
                                                    level: this.addClassForm.level + 1,
                                                    children: []
                                                });
                                            } else {
                                                this.page = 1;
                                                this.currentPageResources = 1;
                                                this.list();
                                            }
                                            this.resetForm();
                                            this.list();
                                        }
                                    })
                                    // if (rowCate === null) {
                                    //     // this.page = Math.ceil((this.pageTotal+1)/10);
                                    //     this.currentPageResources = this.page;
                                    //     this.$message({
                                    //         type: 'success',
                                    //         duration: 500,
                                    //         message: res.msg,
                                    //         onClose: ()=> {
                                    //             this.list();
                                    //             this.addClassForm.name = '';
                                    //             this.addClassForm.id = '';
                                    //             this.dialogAddClass = false;
                                    //         }
                                    //     })
                                    //     return;
                                    // }
                                    // if (!rowCate.children && !(rowCate.children instanceof Array)) {
                                    //     this.$set(rowCate, 'children', [])
                                    // }
                                    // rowCate.children.push({
                                    //     rc_id: res.rc_id,
                                    //     rc_name: this.addClassForm.name,
                                    //     level: this.addClassForm.level+1,
                                    //     children:[],
                                    // });
                                    // this.$message({
                                    //     type: 'success',
                                    //     duration: 500,
                                    //     message: res.msg,
                                    //     onClose: ()=> {
                                    //         this.addClassForm.name = '';
                                    //         this.dialogAddClass = false;
                                    //     }
                                    // })
                                } else {
                                    this.$message.error(res.msg);
                                }
                            },(err) =>{
                                console.log(err);
                            });
                        } else if (this.distinguishBtn === 'edit') {
                            let params = {
                                rc_type: 4,
                                rc_name: this.addClassForm.name,
                                rc_id:this.addClassForm.id
                            }
                            if (this.addClassForm.imageFilePath) {
                                params.up = true;
                                params.file_path = this.addClassForm.imageFilePath;
                            }
                            this.$http.axiosGetBy(this.$api.resourceClassEdit, params, (res) => {
                                if(res.code === 200){
                                    this.$message({
                                        type: 'success',
                                        duration: 500,
                                        message: res.msg,
                                        onClose: ()=> {
                                            this.currentCategory.rc_name = this.addClassForm.name;
                                            this.addClassForm.name = '';
                                            this.dialogAddClass = false;
                                            this.resetForm();
                                            this.list();
                                        }
                                    })
                                } else {
                                    this.$message.error(res.msg);
                                }
                            },(err) =>{
                                console.log(err);
                            });
                        }
                    } else {
                        return false;
                    }
                });
            },
            openAddClass() {
                this.$refs.nameInput.focus();
            },
            deleteClassRow(id, row) {
                if (row.children) {
                    if (row.children.length > 0) {
                        if (row.children.length !== 0) {
                            this.$alert('该分类下存在资源无法删除，先删除资源', '提示消息', {
                                confirmButtonText: '确定',
                                type: 'warning',
                                center: true
                            });
                        }
                    } else {
                        this.$confirm('是否删除该分类，删除完将无法恢复', '提示消息', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                            center: true
                        }).then(() => {
                            this.$http.axiosGetBy(this.$api.resourceClassDel,{rc_id:id},
                                (res) => {
                                    if(res.code === 200){
                                        this.$message.success(res.msg);
                                        this.list();
                                    }else{
                                        this.$message.warning(res.msg);
                                    }
                                },(err) =>{
                                    console.log(err);
                                });

                        }).catch(() => {
                            this.$message.error('已取消删除');
                        })
                    }
                } else {
                    this.$confirm('是否删除该分类，删除完将无法恢复', '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true
                    }).then(() => {
                        this.$http.axiosGetBy(this.$api.resourceClassDel,{rc_id:id},
                            (res) => {
                                if(res.code === 200){
                                    this.$message.success(res.msg);
                                    this.list();
                                }else{
                                    this.$message.warning(res.msg);
                                }
                            },(err) =>{
                                console.log(err);
                            });

                    }).catch(() => {
                        this.$message.error('已取消删除');
                    })
                }
            },
            list() {
                this.$http.axiosGetBy(this.$api.resourceClassList,{type: 4, page: this.page}, (res) => {
                    if(res.code === 200){
                        this.classResourcesTable = res.list;
                        this.pageTotal = res.total;
                    }
                },(err) =>{
                    console.log(err);
                });
            },
            handleCurrentChange(val){
                this.page = val;
                this.list();
            },
            existName(){
                console.log(this.addClassForm.name);
            },
            resetForm(formName) {
                this.$refs.addClassForm.resetFields();
                this.addClassForm = {
                    name: '',
                    id:'',
                    level:'',
                    image: '',
                    // imageFile:'',
                    // imageFilePath:'',
                }
            }
        },
        mounted() {
            this.expands = this.getRowKeys(this.classResourcesTable);
        }

    }
</script>

<style lang="scss" scoped>
    .upload-btn {
        position: relative;
        width: 122px;
        height: 90px;
        background: #D3E8FF;
        border-color: transparent;
        span {
            display: block;
            width: 100%;
            height: 100%;
            line-height: 70px;
            position: relative;
        }
        input {
            width: 122px;
            height: 90px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
    .up-img-show {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 122px;
        height: 90px;
        background-color: #fff;
        border: 1px solid #bfbfbf6b;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .cover-bg {
            position: absolute;
            bottom: 0;
            width: 122px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: #fff;
            background: #000000;
            opacity: 0.5;
            input {
                width: 122px;
                height: 30px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .cover-text {
        position: absolute;
        bottom: -48px;
        left: 0;
        font-size: 12px;
        color: #999;
    }
</style>
