<template>
    <div class="index-container">
        <el-tabs v-model="classTabs" class="index-tabs">
            <el-tab-pane label="素材分类" name="material">
                <div style="position:relative; z-index: 2;">
                    <el-button type="primary" class="add-class-btn" @click="addLevelOne">添加专业大类</el-button>
                </div>
                <el-tabs v-model="materialTabs" type="card" class="resources-content">
                    <el-tab-pane name="resources">
                        <span slot="label" class="tab-txt">
                            <i class="iconfont">&#xe782;</i>资源库
                        </span>
                        <ClassManageResources ref="resourcesChild"></ClassManageResources>
                    </el-tab-pane>
                    <el-tab-pane name="exam">
                        <span slot="label" class="tab-txt">
                            <i class="iconfont">&#xe788;</i>考试库
                        </span>
                        <ClassManageExam ref="examChildren"></ClassManageExam>
                    </el-tab-pane>
                    <el-tab-pane name="project">
                        <span slot="label" class="tab-txt">
                            <i class="iconfont">&#xe789;</i>项目库
                        </span>
                        <ClassManageProject ref="projectChildren"></ClassManageProject>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="课程分类" name="course">
                <div style="position:relative; z-index: 2;">
                    <el-button type="primary" class="add-class-btn" @click="addCourseClass">添加分类</el-button>
                </div>
                <el-tabs v-model="courseTabs" type="card" class="resources-content">
                    <el-tab-pane name="classTab">
                        <span slot="label" class="tab-txt">
                            <i class="iconfont">&#xe782;</i>分类管理
                        </span>
                        <ClassManageCourse ref="courseChild"></ClassManageCourse>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import ClassManageResources from 'components/classManage/ClassManageResources';
    import ClassManageExam from 'components/classManage/ClassManageExam';
    import ClassManageProject from 'components/classManage/ClassManageProject';
    import ClassManageCourse from 'components/classManage/ClassManageCourse';

    export default {
        data() {
            return {
                classTabs: 'material',
                materialTabs: 'resources',
                courseTabs: 'classTab'
            };
        },
        components: {
            ClassManageResources,
            ClassManageExam,
            ClassManageProject,
            ClassManageCourse,
        },
        methods: {
            addLevelOne() {
                if (this.materialTabs === 'resources') {
                    this.$refs.resourcesChild.addClass();
                } else if (this.materialTabs === 'exam'){
                    this.$refs.examChildren.addClass();
                } else if (this.materialTabs === 'project') {
                    this.$refs.projectChildren.addClass();
                }
            },
            addCourseClass() {
                this.$refs.courseChild.addClass();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .class-manage-level {
        padding: 10px 20px 20px;
    }
    ::v-deep .resources-content {
        z-index: 1;
        .tab-txt {
            .iconfont {
                color: #909399;
                font-size: 18px;
                margin-right: 5px;
                position: relative;
                //top: -2px;
            }
        }
        .el-tabs__item.is-active {
            .iconfont {
                color: #409EFF;
            }
        }
        .el-tabs__item:hover {
            .iconfont {
                color: #409EFF;
            }
        }
    }
    .add-class-btn {
        position: absolute;
        top: 0;
        right: 0;
    }
</style>
