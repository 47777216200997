<template>
    <div class="class-manage-resources">
        <el-table :data="classResourcesTable" border style="width: 100%; flex: 1" height="1%" size="medium" :row-key="getRowKey" :expand-row-keys="expands" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="rc_name" label="名称"></el-table-column>
            <el-table-column label="操作" align="right">
                <template slot-scope="scope">
                    <el-button size="small" type="success" v-if="scope.row.level === 1" @click="addClass(scope.row)">添加专业中类</el-button>
                    <el-button size="small" type="primary" v-if="scope.row.level === 2" @click="addClass(scope.row)">添加专业</el-button>
                    <el-button size="small" @click="editClass(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteClassRow(scope.row.rc_id, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-right" :current-page.sync="currentPageResources" layout="prev, pager, next, jumper" :total="pageTotal" @current-change="handleCurrentChange">
        </el-pagination>

        <el-dialog :title="classTitleType" :visible.sync="dialogAddClass" center width="30%" @close="resetForm()" @opened="openAddClass" :close-on-click-modal="false">
            <el-form :model="addClassForm" ref="addClassForm" :rules="resourcesRules" :label-position="labelPosition">
                <el-form-item label="分类名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addClassForm.name" ref="nameInput" autocomplete="off" placeholder="1-20字符" style="width: 80%;" ></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="dialogAddClass = false">取 消</el-button>
                <el-button size="medium" type="primary" @click="addToForm('addClassForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        created(){
            this.list();
        },
        data() {
            return {
                page:1,
                pageTotal:0,
                classResourcesTable: [],
                expands: [],
                currentPageResources: 1,
                currentCategory: null,
                dialogAddClass: false,
                labelPosition: 'left',
                addClassForm: {
                    name: '',
                    id:'',
                    level:'',
                },
                formLabelWidth: '80px',
                classTitleType: '',
                distinguishBtn: '',
                resourcesRules: {
                    name: [
                        { required: true, message: '请输入分类名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                }
            }
        },
        methods: {
            getRowKey(row) {
                return row.rc_id;
            },
            getRowKeys(data) {
                let arr = [];
                if (data.length === 0) {
                    return arr;
                }
                arr.push(data[0].rc_id);
                if (data[0].children && data[0].children.length > 0) {
                    let result = this.getRowKeys(data[0].children);
                    arr = arr.concat(result);
                }
                return arr;
            },
            addClass(row = null) {
                if(row){
                    this.addClassForm.id = row.rc_id;
                    this.addClassForm.level = row.level;
                    if (row.level === 1) {
                        this.classTitleType = '添加专业中类'
                    }
                    if (row.level === 2) {
                        this.classTitleType = '添加专业'
                    }
                }else{
                    this.addClassForm.level = 0;
                    this.classTitleType = '添加专业大类'
                }
                this.dialogAddClass = true;
                this.currentCategory = row;
                this.distinguishBtn = 'add';
            },
            editClass(row = null) {
                if(row){
                    this.addClassForm.id = row.rc_id;
                    this.addClassForm.level = row.level;
                    if (row.level === 1) {
                        this.classTitleType = '编辑专业大类'
                    }
                    if (row.level === 2) {
                        this.classTitleType = '编辑专业中类'
                    }
                    if (row.level === 3) {
                        this.classTitleType = '编辑专业'
                    }
                }else{
                    this.addClassForm.level = 0;
                }
                this.dialogAddClass = true;
                this.currentCategory = row;
                this.addClassForm.name = row.rc_name;
                this.distinguishBtn = 'edit';
            },
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            rc_type: 1,
                            rc_name:this.addClassForm.name,
                        };
                        if (this.addClassForm.id) {
                            params.rc_id = this.addClassForm.id
                        }
                        if (this.distinguishBtn === 'add') {
                            let rowCate = this.currentCategory;
                            this.$http.axiosGetBy(this.$api.resourceClassAdd, params, (res) => {
                                if(res.code === 200){
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: res.msg,
                                        onClose: ()=> {
                                            this.dialogAddClass = false;
                                            if (this.addClassForm.id) {
                                                this.currentCategory.children.unshift({
                                                    rc_id: res.rc_id,
                                                    rc_name: this.addClassForm.name,
                                                    rc_parent_id: this.addClassForm.id,
                                                    level: this.addClassForm.level + 1,
                                                    children: []
                                                });
                                            } else {
                                                this.page = 1;
                                                this.currentPageResources = 1;
                                                this.list();
                                            }
                                            this.resetForm();
                                        }
                                    })
                                    // if (rowCate === null) {
                                    //     // this.page = Math.ceil((this.pageTotal+1)/10);
                                    //     this.currentPageResources = this.page;
                                    //     this.$message({
                                    //         type: 'success',
                                    //         duration: 500,
                                    //         message: res.msg,
                                    //         onClose: ()=> {
                                    //             this.list();
                                    //             this.addClassForm.name = '';
                                    //             this.addClassForm.id = '';
                                    //             this.dialogAddClass = false;
                                    //         }
                                    //     })
                                    //     return;
                                    // }
                                    // if (!rowCate.children && !(rowCate.children instanceof Array)) {
                                    //     this.$set(rowCate, 'children', [])
                                    // }
                                    // rowCate.children.push({
                                    //     rc_id: res.rc_id,
                                    //     rc_name: this.addClassForm.name,
                                    //     level: this.addClassForm.level+1,
                                    //     children:[],
                                    // });
                                    // this.$message({
                                    //     type: 'success',
                                    //     duration: 500,
                                    //     message: res.msg,
                                    //     onClose: ()=> {
                                    //         this.addClassForm.name = '';
                                    //         this.dialogAddClass = false;
                                    //     }
                                    // })
                                } else {
                                    this.$message.error(res.msg);
                                }
                            },(err) =>{
                                console.log(err);
                            });

                        } else if (this.distinguishBtn === 'edit') {
                            this.$http.axiosGetBy(this.$api.resourceClassEdit,{rc_type: 1, rc_id:this.addClassForm.id, rc_name:this.addClassForm.name}, (res) => {
                                if(res.code === 200){
                                    this.$message({
                                        type: 'success',
                                        duration: 500,
                                        message: res.msg,
                                        onClose: ()=> {
                                            this.currentCategory.rc_name = this.addClassForm.name;
                                            this.addClassForm.name = '';
                                            this.dialogAddClass = false;
                                            this.resetForm();
                                        }
                                    })
                                } else {
                                    this.$message.error(res.msg);
                                }
                            },(err) =>{
                                console.log(err);
                            });
                        }
                    } else {
                        return false;
                    }
                });
            },
            openAddClass() {
                this.$refs.nameInput.focus();
            },
            deleteClassRow(id, row) {
                if (row.children.length !== 0) {
                    this.$alert('该分类下存在资源无法删除，先删除资源', '提示消息', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        center: true
                    });
                } else {
                    this.$confirm('是否删除该分类，删除完将无法恢复', '提示消息', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true
                    }).then(() => {
                        this.$http.axiosGetBy(this.$api.resourceClassDel,{rc_id:id},
                            (res) => {
                                if(res.code === 200){
                                    this.$message.success(res.msg);
                                    this.list();
                                }else{
                                    this.$message.success(res.msg);
                                }
                            },(err) =>{
                                console.log(err);
                            });

                    }).catch(() => {
                        this.$message.error('已取消删除');
                    })
                }
            },
            list() {
                this.$http.axiosGetBy(this.$api.resourceClassList,{type: 1, page: this.page}, (res) => {
                    if(res.code === 200){
                        this.classResourcesTable = res.list;
                        this.pageTotal = res.total;
                    }
                },(err) =>{
                    console.log(err);
                });
            },
            handleCurrentChange(val){
                this.page = val;
                this.list();
            },
            existName(){
                console.log(this.addClassForm.name);
            },
            resetForm(formName) {
                this.$refs.addClassForm.resetFields();
                this.addClassForm = {
                    name: '',
                    id:'',
                    level:''
                }
            }
        },
        mounted() {
            this.expands = this.getRowKeys(this.classResourcesTable);
        }

    }
</script>

<style lang="scss" scoped>
</style>
